import { useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import About from "./components/About/About";
import Banner from "./components/Banner/Banner";
import CallToAction from "./components/CallToAction/CallToAction";
import Choose from "./components/Choose/Choose";
import Disclaimer from "./components/Disclaimer/Disclaimer";
import Footer from "./components/Global/Footer/Footer";
import Header from './components/Global/Header/Header';
import Saying from "./components/Saying/Saying";
import Working from "./components/Working/Working";

function App() {
  useEffect(()=>{
    AOS.init({
      duration: 1000,
      disable: function() {
        var maxWidth = 768;
        return window.innerWidth < maxWidth;
      }
    }); 
  },[]);
  return (
    <>
      <Header/>
      <Banner/>
      <Choose/>
      <Working/>
      <About/>
      <Saying/>
      <CallToAction/>
      <Disclaimer/>
      <Footer/>
    </>
  );
}

export default App;
import React from 'react'
import './CallToAction.scss'
const CallToAction = () => {
    return (
        <div className="call-to-action">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="call-action-text text-center">
                            <div className="h2 section-title" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                Looking for <b>financial planner</b>?
                            </div>
                            <p data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1100">
                                Let’s schedule a call with me today.
                            </p>
                            <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1200">
                                <a href='https://wa.me/+6593678685' target="_blank" className="btn-lg">
                                    Whatsapp Me
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallToAction

import React from "react";
import Slider from "react-slick";
import userImg from '../../img/user.svg'
import { FaStar } from "react-icons/fa";
const sayingData = [
    {
        desc: "Wonderful and caring financial agent I've ever encountered! Since I've known Grace Yang in April 2016, both my life and my understanding of safety and financial planning have expanded. To make sure my wife and I have a long-lasting medical insurance coverage, she has suggested a good and reasonable protection. Always be informed about the state of the market, and find a qualified advisor for the kinds of assets and funds you should consider. Grace Yang is unquestionably a customer-focused agent who suggested the best insurance for each period of your life. I'll give you 11 cents for every ten!",
        userImg: userImg,
        userName: 'Mr. Poh',
        userDesc: 'Customer',
    },
    {
        desc: "Grace is a wonderful representative that goes above and beyond. She has handled all of my business insurance and has assisted me with the employee benefits. She responds quickly and will follow up with us as necessary because of our deal. I appreciate her assisting us in every way.",
        userImg: userImg,
        userName: 'Alex',
        userDesc: 'Customer',
    },
    {
        desc: "Grace has been a huge assistance to me as I organize my finances. She is a very committed and accountable agent who consistently provides excellent advice and genuinely cares about your financial situation to assist you. She won't try to force-sell you something in which you have no interest. She is also very approachable and pleasant. I'm delighted I gave her control over my finances.",
        userImg: userImg,
        userName: 'Mr. Koh',
        userDesc: 'Customer',
    },
    {
        desc: "I first met Grace Yang in 2016 and she helped me buy my first life insurance. I really didn't understand the value of protection and the need for life insurance back then. Grace was the one who patiently answered all of my questions and provided explanations. She was incredibly educated about each product and made sure I knew what I was getting before I made a purchase. Her honesty impressed me. She checks to see if the coverage is appropriate for each client depending on their age, way of life, and financial situation. She consequently became both my buddy and financial advisor after that.",
        userImg: userImg,
        userName: 'Ms. Aileen',
        userDesc: 'Customer',
    },
]
const SayingPhone = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className="saying-slid-area"> 
            <Slider {...settings} className="saying-slid">
                {sayingData.map((item)=>(
                    <div className="saying-item">
                        <p>
                            {item.desc}    
                        </p>
                        <div className="client-user">
                            <div className="client-name d-flex gap-3 align-items-center">
                                <img src={item.userImg} alt="" />
                                <div className="text">
                                    <div className="h6">{item.userName}</div>
                                    <p>{item.userDesc}</p>
                                </div>
                            </div>
                            <div className="stet">
                                <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                            </div>
                        </div>
                    </div> 
                ))}
            </Slider>  
        </div>
    )
}

export default SayingPhone
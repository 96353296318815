import React from 'react'
import bannerImg from '../../img/253c3e98-3bd4-4793-a475-31b0343b79a2.jpg'
import Services from '../Services/Services'
import "./Banner.scss"
const Banner = () => {
    return (
        <div className="banner-wrapper">
            <div className="banner-area">
                <div className="container">
                    <div className="row row-gap">
                        <div className="col-lg-5 align-self-center">
                            <div className="banner-text">
                                <h1 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                    Grace Yang
                                </h1>
                                <p data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1100">
                                    Every business owner longed for smooth sailing in their business but often it’s not what it seems to be, so i’m here to balance through the waves with them.
                                </p>
                                <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1200">
                                    <a href='https://wa.me/93678685' target="_blank" className="btn-lg">Whatsapp Me</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 ms-auto align-self-center">
                            <div className="banner-img" data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-duration="1300">
                                <img src={bannerImg} alt="" className='responsive' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Services />
        </div>
    )
}

export default Banner
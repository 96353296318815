import React from 'react'
import './Disclaimer.scss'
const Disclaimer = () => {
    return (
        <div className="disclaimer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="h6">Disclaimer</div>
                        <p>
                            The website / account of the social media platform (e.g. Facebook / Instagram / Twitter / LinkedIn / Blog) belongs to a licensed representative or a group of licensed representatives of Synergy Financial Advisers Pte Ltd (“SYNERGY”). The owner(s) is wholly responsible for the content contained in the website / account. It does not represent SYNERGY’s views, opinions or advices.
                        </p>
                        <br />
                        <p>
                            The website / account is for informational purposes only and is not endorsed by SYNERGY nor does it constitute any official communication of SYNERGY. Therefore, SYNERGY cannot be liable for the content or confirm the accuracy of the information.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer
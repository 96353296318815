import React from 'react'
import logo from '../../../img/logo.svg'
import { FaInstagram,FaFacebookF } from "react-icons/fa";
import './Footer.scss'
const Footer = () => {
    return (
        <>
        <div className="footer-area d-none d-lg-block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 align-self-center">
                        <div className="footer-item">
                            <div className="social">
                                <a target="_blank" href="https://www.instagram.com/gracey_giselle/"><FaInstagram/></a>
                                <a target="_blank" href="https://www.facebook.com/profile.php?id=100084996164712"><FaFacebookF/></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 align-self-center">
                        <div className="footer-item text-center">
                            <div className="logo">
                                <a href="/"><img src={logo} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 align-self-center">
                        <div className="footer-item">
                            <p>2022 @ Grace Yang</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-phone d-lg-none">
            <div className="logo">
                <a href="/"><img src={logo} alt="" /></a>
            </div>
            <div className="footer-item">
                <div className="social">
                    <a target="_blank" href="https://www.instagram.com/gracey_giselle/"><FaInstagram/></a>
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=100084996164712"><FaFacebookF/></a>
                </div>
            </div>
            <div className="footer-item">
                <p>2022 @ Grace Yang</p>
            </div>
        </div>
        </>
    )
}

export default Footer
import React from "react";
import Slider from "react-slick";
import logo1 from '../../img/work/1.svg'
import logo2 from '../../img/work/2.svg'
import logo3 from '../../img/work/3.svg'
import logo4 from '../../img/work/4.svg'
import logo5 from '../../img/work/5.svg'
import logo6 from '../../img/work/6.svg'
import logo7 from '../../img/work/7.svg'
import logo8 from '../../img/work/8.svg'
import logo9 from '../../img/work/9.svg'
import logo10 from '../../img/work/10.svg'
import logo11 from '../../img/work/11.svg'
import logo12 from '../../img/work/12.svg' 
import './Working.scss'
const workingData = [
    {
        img1: logo1,
        img2: logo2,
        img3: logo3,
        img4: logo4,
        img5: logo5,
        img6: logo6,
        img7: logo7,
        img8: logo8,
        img9: logo9,
        img10: logo10,
        img11: logo11,
        img12: logo12,
    }, 
]
const Working = () => {
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [ 
            {
              breakpoint: 600,
              settings: {
                dots: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                dots: false,
              }
            }
        ]
      };
    return (
        <div className="working-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <div className="h2 section-title" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                Working <span>Partners</span>
                            </div>
                        </div> 
                        <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1200">
                            <Slider {...settings} className="working-slid" >
                                {workingData.map((item)=>(
                                <div className="working-slid-item">
                                    <div className="working-items">
                                        <div className="working-logo">
                                            <img src={item.img1} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img2} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img3} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img4} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img5} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img6} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img7} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img8} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img9} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img10} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img11} alt="" />
                                        </div>
                                        <div className="working-logo">
                                            <img src={item.img12} alt="" />
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </Slider> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Working
import React, { useEffect, useState } from "react";
import logo from '../../../img/logo.svg'
import './Header.scss'
const Header = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 1);
        });
    }, []); 
    return (
        <div className={scroll ? "header-area topnav" : "header-area"}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <a href="/">
                                <img src={logo} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
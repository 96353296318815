import React from 'react'
import './Services.scss'
const servicesData = [
    {
        title: 'Long term value to their business',
        desc: 'Optimising and building long term business plan.'
    },
    {
        title: 'Financial Planning',
        desc: 'Effective planning for your  businesses, workers, and families.'
    },
    {
        title: 'Protecting your Employees',
        desc: 'Safe guard your team and businesses.'
    },
]
const Services = () => {
    return (
        <div className="services-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="services-content">
                            {servicesData.map((item)=>(
                                <div className="services-item" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                    <div className="h6">{item.title}</div>
                                    <p>{item.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
import React from 'react'
import aboutImg from '../../img/about-me.png'
import './About.scss'
const About = () => {
    return (
        <div className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center order-lg-1">
                        <div className="about-img" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                            <img src={aboutImg} alt="" className='responsive' />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="about-text">
                            <div className="h2 section-title" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                <span>About</span> Me
                            </div>
                            <p data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1100">
                                Grace Yang began working in the financial planning industry in 2015, and she has been doing so for the past 7 years. Entering this industry with an open mind and assisting others with their financial portfolio. She has always done her best over the years to assist and support her clients with the advice she has given. 
                            </p>
                            <br />
                            <p data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1200">
                                She has always maintained a positive relationship with her clients, and this relationship has gradually evolved into friendship. Grace believes that developing a positive relationship with her clients is essential in order to foster greater trust between them. 
                            </p>
                            <br />
                            <p data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1300">
                                Grace's main focus is on assisting her clients and prospects in maintaining a healthy financial portfolio while also being creative and thinking outside the box.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default About
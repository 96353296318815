import React from 'react'
import choose from '../../img/choose.svg'
import './Choose.scss'
const Choose = () => {
    return (
        <div className="choose-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div className="choose-img" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                            <img src={choose} alt="" className='responsive' />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="choose-text">
                            <div className="h2 section-title" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1100">
                                Why choose me as 
                                <br />
                                <span>your adviser?</span>
                            </div>
                            <p data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1200">
                                In order to optimize the long-term value to their business for themselves, their employees and their families. 
                            </p>
                            <br />
                            <p data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1300">
                                Grace has worked with business owners in the fields of events, construction, F&B, manufacturing, logistics and salons.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Choose